import '../styles/components/spinner.scss';

import * as React from 'react';

export const Spinner: React.FC = () => {
  return (
    <div className="spinner" data-testid="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

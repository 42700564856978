import clsx from 'clsx';
import * as React from 'react';

import { Spinner } from './spinner';

type Variant = 'primary' | 'link' | 'close';

type ButtonProps = {
  className?: string;
  type?: React.ButtonHTMLAttributes<unknown>['type'];
  variant?: Variant;
  disabled?: boolean;
  busy?: boolean;
  onClick?: () => void;
};

export const Button: React.FC<ButtonProps> = ({
  className,
  type = 'button',
  variant,
  onClick,
  disabled,
  children,
  busy,
  ...props
}) => {
  return (
    <button
      type={type}
      className={clsx('button', `button--${variant}`, className)}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {!busy && <>{children}</>}
      {busy && <Spinner />}
    </button>
  );
};
